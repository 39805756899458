import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { getCommunityList, getAdminNewNumber, getNewNumberPercentage, getQueryParamsObj, exportExcel } from '@/api'
import Table from '@mui/material/Table'
import Button from '@mui/material/Button'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import LoadingBox from '@/components/baseMap/loadingBox'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0.5vh',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    borderRadius: '5px 5px 0 0',
    '@media (max-width: 1280px)': {
      fontSize: '1.9vh',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '1.8vh',
    lineHeight: '34px',
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.6vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '1.4vh',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
    color: '#000',
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1280px)': {
      height: '15%',
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  tableBox: {
    width: '100%',
    height: '93%',
    borderRadius: 5,
    boxShadow: '-1px 1px 15px -4px #000',
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'auto',
    '@media (max-width: 1280px)': {
      height: '85%',
    },
  },
  totalBox: {
    display: 'flex',
    width: '100%',
    background: '#fff',
    borderRadius: ' 0 0 5px 5px',
    minWidth: 1380,
    '&>div': {
      width: '7.9%',
      padding: '1vh 0.5vh',
      fontSize: '2vh',
      lineHeight: 1.5,
      fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
      fontWeight: 600,
      textAlign: 'right',
      '@media (max-width: 1280px)': {
        fontSize: '1.6vh',
      },
    },
  },
  btn: {
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
      height: 'auto',
    },
  },
  selectBigBox: {
    width: '20%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '40%',
    },
  },
  loadBox: {
    position: 'absolute',
    zIndex: 5,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
  },
  textField: {
    width: '65%',
    textAlign: 'center',
    color: '#000',
    fontSize: 20,
    height: 40,
    padding: 0,
    border: '2px solid #000',
    borderRadius: 5,
    background: 'transparent',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    '&::before,&::after': {
      display: 'none',
    },
  },
}))

const AdminPropertyDetails = () => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [paddingBoo, setPaddingBoo] = useState(false)
  const [loadingBoo, setLoadingBoo] = useState(true)
  const [communityArr, setCommunityArr] = useState<any>([])
  const [arrExportList, setArrExportList] = useState<any>({})
  const [buildingObj, setBuildingObj] = useState<any>({})
  const [totalObj, setTotalObj] = useState<any>({
    currentYearAccountsReceivable: 0,
    currentYearIncomeMetric: 0,
    overYearsIncomeMetric: 0,
    historicalArrears: 0,
    preYearArrears: 0,
    currentYearArrears: 0,
    currentMonthActual: 0,
    currentYearPrepaidActual: 0,
    preYearAllocCurrentMonth: 0,
  })
  const [dateValue, setDateValue] = useState<any>('')
  const [communityHeaderArr, setCommunityHeaderArr] = useState<any>([
    {
      key: 1,
      name: '小区',
      width: '5.2%',
    },
    {
      key: 2,
      name: '当年应收',
      width: '7.9%',
    },
    {
      key: 3,
      name: '当年指标',
      width: '7.9%',
    },
    {
      key: 4,
      name: '当年预收',
      width: '7.9%',
    },
    {
      key: 5,
      name: '1-12月完成',
      width: '7.9%',
    },
    // {
    //   key: 6,
    //   name: '当年完成数',
    //   width: '7.9%',
    // },
    {
      key: 6,
      name: '指标完成数',
      width: '7.9%',
    },
    {
      key: 7,
      name: '当年完成率',
      width: '7.9%',
    },
    {
      key: 8,
      name: '旧欠指标',
      width: '7.9%',
    },
    {
      key: 9,
      name: '旧欠完成数',
      width: '7.9%',
    },
    {
      key: 10,
      name: '旧欠完成率',
      width: '7.9%',
    },
    {
      key: 11,
      name: '总计指标',
      width: '7.9%',
    },
    {
      key: 12,
      name: '总计完成数',
      width: '7.9%',
    },
    {
      key: 13,
      name: '总计完成率',
      width: '7.9%',
    },
  ])

  const propertyCommunity = getQueryParamsObj(window.location?.search)
  const totalBox = document.getElementById('totalBox')
  const tableBigBox = document.getElementById('tableBigBox')
  const tableBox = document.getElementById('tableBox')

  useEffect(() => {
    if (tableBigBox?.clientHeight && tableBox?.clientHeight && communityArr?.length > 0) {
      if (tableBox?.clientHeight <= tableBigBox?.clientHeight) {
        !paddingBoo && setPaddingBoo(true)
      } else {
        paddingBoo && setPaddingBoo(false)
      }
    }
  }, [communityArr])

  const typeObj: any = {
    '5': '管理费',
    '6': '租金',
    '7': '商铺管理费',
    '8': '停车费',
    '17': '管理费',
    '18': '租金',
    '19': '商铺管理费',
    '20': '停车费',
  }

  const exportObj = (res: any, titleText: any) => {
    let arrObj: any = {}
    arrObj = {
      '!cols': [],
      '!fullref': `A1:M${(res?.length || 0) + 1}`,
      '!ref': `A1:M${(res?.length || 0) + 1}`,
      '!rows': [],
      A1: { t: 's', v: '小区' },
      B1: { t: 's', v: '当年应收' },
      C1: { t: 's', v: '当年指标' },
      D1: { t: 's', v: '当年预收' },
      E1: { t: 's', v: titleText },
      // E1: { t: 's', v: '当年完成数' },
      F1: { t: 's', v: '指标数完成数' },
      G1: { t: 's', v: '当年完成率' },
      H1: { t: 's', v: '旧欠指标' },
      I1: { t: 's', v: '旧欠完成数' },
      J1: { t: 's', v: '旧欠完成率' },
      K1: { t: 's', v: '总计指标' },
      L1: { t: 's', v: '总计完成数' },
      M1: { t: 's', v: '总计完成率' },
    }
    res?.forEach((item: any, i: number) => {
      arrObj[`A${i + 2}`] = { t: 's', v: item.projectName || '' }
      arrObj[`B${i + 2}`] = { t: 'n', v: item.currentYearAccountsReceivable || '' }
      arrObj[`C${i + 2}`] = { t: 'n', v: item.currentYearIncomeMetric || 0 }
      arrObj[`D${i + 2}`] = { t: 'n', v: item.preYearAllocCurrentMonth || 0 }
      arrObj[`E${i + 2}`] = { t: 'n', v: item.numberOfCompletions || 0 }
      // arrObj[`E${i + 2}`] = {
      //   t: 'n',
      //   v: item?.currentYearArrears + item?.currentMonthActual + item?.currentYearPrepaidActual || 0,
      // }
      arrObj[`F${i + 2}`] = {
        t: 'n',
        v:
          item.projectId === '112' && typeObj[propertyCommunity?.typeValue] === '管理费'
            ? 0
            : item?.currentYearArrears +
                item?.currentMonthActual +
                item?.currentYearPrepaidActual +
                item?.preYearAllocCurrentMonth || 0,
      }
      arrObj[`G${i + 2}`] = {
        t: 'n',
        v:
          `${item?.currentYearIncomeMetric}` !== '0'
            ? `${getNewNumberPercentage(
                (item?.currentYearArrears +
                  item?.currentMonthActual +
                  item?.currentYearPrepaidActual +
                  item?.preYearAllocCurrentMonth) /
                  item?.currentYearIncomeMetric,
                2,
              )}`
            : `0%`,
      }
      arrObj[`H${i + 2}`] = { t: 'n', v: item.overYearsIncomeMetric || 0 }
      arrObj[`I${i + 2}`] = { t: 'n', v: item.preYearArrears + item.historicalArrears || 0 }
      arrObj[`J${i + 2}`] = {
        t: 'n',
        v:
          `${item?.overYearsIncomeMetric}` !== '0'
            ? `${getNewNumberPercentage(
                (item.preYearArrears + item.historicalArrears) / item?.overYearsIncomeMetric,
                2,
              )}`
            : `0%` || 0,
      }
      arrObj[`K${i + 2}`] = { t: 'n', v: item?.currentYearIncomeMetric + item?.overYearsIncomeMetric || 0 }
      arrObj[`L${i + 2}`] = {
        t: 'n',
        v:
          item.projectId === '112' && typeObj[propertyCommunity?.typeValue] === '管理费'
            ? 0
            : item?.currentYearArrears +
                item?.currentMonthActual +
                item?.currentYearPrepaidActual +
                item?.preYearArrears +
                item?.historicalArrears +
                item?.preYearAllocCurrentMonth || 0,
      }
      arrObj[`M${i + 2}`] = {
        t: 'n',
        v:
          `${item?.overYearsIncomeMetric + item?.currentYearIncomeMetric}` !== '0'
            ? `${getNewNumberPercentage(
                (item?.currentYearArrears +
                  item?.currentMonthActual +
                  item?.currentYearPrepaidActual +
                  item?.preYearArrears +
                  item.historicalArrears +
                  item.preYearAllocCurrentMonth) /
                  (item?.overYearsIncomeMetric + item?.currentYearIncomeMetric),
                2,
              )}`
            : `0%` || 0,
      }
    })
    setArrExportList(arrObj)
  }

  const getNewTitleArr = (date: any) => {
    const newArr: any = []
    communityHeaderArr?.map((item: any) => {
      if (item.key === 5) {
        newArr.push({
          key: 5,
          name: `1-${Number(moment.utc(date).format('MM'))}月完成`,
          width: '7.9%',
        })
      } else {
        newArr.push(item)
      }
    })
    setCommunityHeaderArr(newArr)
  }

  const condition = (sourceName: any, incomeType: any, paymentMethod: any) => {
    let boo = false
    if (paymentMethod !== '改单') {
      if (sourceName === '一通票据') {
        if (incomeType === '非日常') {
          boo = true
        } else {
          boo = incomeType === '日常' && paymentMethod === '转账'
        }
      } else if (sourceName === '一通应收帐' && paymentMethod !== '转账') {
        boo = true
      } else if (sourceName === '通联') {
        boo = true
      } else if (sourceName === '停车系统') {
        boo = true
      }
    }
    return boo
  }

  const getCommunityData = (obj: any) => {
    getNewTitleArr(obj?.date)
    setTotalObj({
      currentYearAccountsReceivable: 0,
      currentYearIncomeMetric: 0,
      overYearsIncomeMetric: 0,
      historicalArrears: 0,
      preYearArrears: 0,
      currentYearArrears: 0,
      currentMonthActual: 0,
      currentYearPrepaidActual: 0,
      preYearAllocCurrentMonth: 0,
    })
    setBuildingObj({})
    setCommunityArr([])
    getCommunityList(moment.utc(obj?.date).format('YYYY'), 12, obj?.projectId, typeObj[obj?.typeValue]).then(
      (res: any) => {
        setLoadingBoo(false)
        const overdueObj = res?.overdue
        const metricsObj = res?.metrics
        const incomeSObj = res?.incomes
        const newArr: any = []
        const allArr: any = []
        overdueObj?.map((item: any) => {
          const childObj: any = metricsObj[item.projectId]?.filter(
            (items: any) => items.assessType === typeObj[obj?.typeValue],
          )[0]
          newArr.push({
            year: item.year,
            projectId: item.projectId,
            projectName: item.projectName,
            chargeName: item.chargeName,
            preYearAlloc: childObj?.preYearAlloc,
            currentYearAccountsReceivable: item.currentYearAccountsReceivable,
            currentYearIncomeMetric: childObj?.currentYearMetric,
            overYearsIncomeMetric: childObj?.overYearMetric,
          })
        })
        newArr?.map((item: any) => {
          let historicalArrears: any = 0
          let preYearArrears: any = 0
          let currentYearArrears: any = 0
          let currentMonthActual: any = 0
          let currentYearPrepaidActual: any = 0
          let preYearAllocCurrentMonth: any = 0
          let numberOfCompletions: any = 0
          incomeSObj?.map((items: any) => {
            if (
              item.projectId === items.projectId &&
              items?.incomeName !== '临时停车费' &&
              condition(items.sourceName, items.incomeType, items.paymentMethod)
            ) {
              preYearAllocCurrentMonth += items.preYearAllocCurrentMonth
              historicalArrears += items.historicalArrears
              preYearArrears += items.preYearArrears
              if (items.month <= Number(moment.utc(obj?.date).format('MM'))) {
                numberOfCompletions +=
                  items?.currentYearArrears + items?.currentMonthActual + items?.currentYearPrepaidActual
                currentYearArrears += items.currentYearArrears
                currentMonthActual += items.currentMonthActual
                currentYearPrepaidActual += items.currentYearPrepaidActual
              }
            }
          })
          allArr.push({
            ...item,
            historicalArrears,
            preYearArrears,
            currentYearArrears,
            currentMonthActual,
            currentYearPrepaidActual,
            preYearAllocCurrentMonth,
            // preYearAllocCurrentMonth:
            //   item?.preYearAlloc || item?.preYearAlloc === 0 ? item?.preYearAlloc : preYearAllocCurrentMonth,
            numberOfCompletions,
          })
        })
        const buildArr = allArr?.filter((res: any) => res.projectId === '112')
        setBuildingObj(buildArr?.length > 0 ? buildArr[0] : {})
        exportObj(allArr, `1-${Number(moment.utc(obj?.date).format('MM'))}月完成`)
        setCommunityArr(allArr || [])
        let currentYearAccountsReceivableNum: any = 0
        let currentYearIncomeMetricNum: any = 0
        let overYearsIncomeMetricNum: any = 0
        let historicalArrearsNum: any = 0
        let preYearArrearsNum: any = 0
        let currentYearArrearsNum: any = 0
        let currentMonthActualNum: any = 0
        let currentYearPrepaidActualNum: any = 0
        let preYearAllocCurrentMonthNum: any = 0
        let numberOfCompletionsNum: any = 0
        allArr?.map((item: any, i: any) => {
          currentYearAccountsReceivableNum += item?.currentYearAccountsReceivable
          currentYearIncomeMetricNum += item?.currentYearIncomeMetric
          overYearsIncomeMetricNum += item?.overYearsIncomeMetric
          historicalArrearsNum += item?.historicalArrears
          preYearArrearsNum += item?.preYearArrears
          currentYearArrearsNum += item?.currentYearArrears
          currentMonthActualNum += item?.currentMonthActual
          currentYearPrepaidActualNum += item?.currentYearPrepaidActual
          preYearAllocCurrentMonthNum += item?.preYearAllocCurrentMonth
          numberOfCompletionsNum += item?.numberOfCompletions
          if (i === allArr?.length - 1) {
            setTotalObj({
              currentYearAccountsReceivable: currentYearAccountsReceivableNum,
              currentYearIncomeMetric: currentYearIncomeMetricNum,
              overYearsIncomeMetric: overYearsIncomeMetricNum,
              historicalArrears: historicalArrearsNum,
              preYearArrears: preYearArrearsNum,
              currentYearArrears: currentYearArrearsNum,
              currentMonthActual: currentMonthActualNum,
              currentYearPrepaidActual: currentYearPrepaidActualNum,
              preYearAllocCurrentMonth: preYearAllocCurrentMonthNum,
              numberOfCompletions: numberOfCompletionsNum,
            })
          }
        })
      },
    )
  }

  useEffect(() => {
    if (
      propertyCommunity?.typeValue &&
      propertyCommunity?.id &&
      propertyCommunity?.date &&
      ((Number(propertyCommunity?.typeValue) > 4 && Number(propertyCommunity?.typeValue) < 9) ||
        (Number(propertyCommunity?.typeValue) > 16 && Number(propertyCommunity?.typeValue) < 21))
    ) {
      setLoadingBoo(true)
      setDateValue(propertyCommunity?.date)
      getCommunityData({ ...propertyCommunity, projectId: '全部' })
    }
  }, [propertyCommunity?.typeValue, propertyCommunity?.date])

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.selectBigBox}>
          <div className={classes.titleText}>时间： </div>
          <input
            type="month"
            value={dateValue}
            className={classes.textField}
            onChange={(event: any) => {
              setDateValue(event.target.value)
            }}
          />
        </div>
        <div>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => {
              setLoadingBoo(true)
              if (dateValue === propertyCommunity?.date) {
                getCommunityData({
                  ...propertyCommunity,
                  projectId: '全部',
                  date: dateValue,
                })
              }
              navigate(
                `?id=${propertyCommunity?.id || 101}&date=${dateValue}&typeValue=${propertyCommunity?.typeValue}`,
              )
            }}
            variant="contained"
            className={classes.btn}
          >
            查询
          </Button>
          <Button
            onClick={() => {
              exportExcel(
                arrExportList,
                `${moment.utc(propertyCommunity?.date).format('YYYY')}-${
                  typeObj[propertyCommunity?.typeValue]
                }-应收汇总-${moment.utc(new Date()).format('YYYY-MM-DD')}.xlsx`,
              )
            }}
            variant="contained"
            className={classes.btn}
          >
            导出
          </Button>
        </div>
      </div>
      <div className={classes.tableBox}>
        {loadingBoo && (
          <div className={classes.loadBox}>
            <LoadingBox />
          </div>
        )}
        <div
          style={{ maxHeight: `calc( 100% - ${totalBox?.clientHeight}px )`, overflow: 'auto', minWidth: 1380 }}
          id="tableBigBox"
        >
          <Table id="tableBox" stickyHeader aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {communityHeaderArr?.map((item: any) => {
                  return (
                    <StyledTableCell style={{ width: item.width }} key={item.key} align="right">
                      {item.name}
                    </StyledTableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {communityArr?.map((item: any, i: any) => {
                return (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="right">{item?.projectName}</StyledTableCell>
                    <StyledTableCell align="right">
                      {getAdminNewNumber(item?.currentYearAccountsReceivable, 2) || 0}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {getAdminNewNumber(item?.currentYearIncomeMetric, 2) || 0}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {getAdminNewNumber(item?.preYearAllocCurrentMonth, 2) || 0}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {getAdminNewNumber(item?.numberOfCompletions, 2) || 0}
                    </StyledTableCell>
                    {/*<StyledTableCell align="right">*/}
                    {/*  {getAdminNewNumber(*/}
                    {/*    item?.currentYearArrears + item?.currentMonthActual + item?.currentYearPrepaidActual,*/}
                    {/*    2,*/}
                    {/*  ) || 0}*/}
                    {/*</StyledTableCell>*/}
                    <StyledTableCell align="right">
                      <BootstrapTooltip
                        title={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `收当年欠：${item?.currentYearArrears?.toFixed(
                                2,
                              )}<br/>收当月：${item?.currentMonthActual?.toFixed(
                                2,
                              )}<br/>收当年：${item?.currentYearPrepaidActual?.toFixed(
                                2,
                              )}<br/>当年预收：${item?.preYearAllocCurrentMonth?.toFixed(2)}`,
                            }}
                          />
                        }
                        placement="right"
                      >
                        <div>
                          {item.projectId === '112' && typeObj[propertyCommunity?.typeValue] === '管理费'
                            ? 0
                            : getAdminNewNumber(
                                item?.currentYearArrears +
                                  item?.currentMonthActual +
                                  item?.currentYearPrepaidActual +
                                  item?.preYearAllocCurrentMonth,
                                2,
                              ) || 0}
                        </div>
                      </BootstrapTooltip>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {`${item?.currentYearIncomeMetric}` !== '0'
                        ? `${getNewNumberPercentage(
                            (item?.currentYearArrears +
                              item?.currentMonthActual +
                              item?.currentYearPrepaidActual +
                              item?.preYearAllocCurrentMonth) /
                              item?.currentYearIncomeMetric,
                            2,
                          )}`
                        : `0%`}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {getAdminNewNumber(item?.overYearsIncomeMetric, 2) || 0}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <BootstrapTooltip
                        title={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `收上年欠：${item.preYearArrears?.toFixed(
                                2,
                              )}<br/>收历年欠：${item.historicalArrears?.toFixed(2)}`,
                            }}
                          />
                        }
                        placement="right"
                      >
                        <div>{getAdminNewNumber(item.preYearArrears + item.historicalArrears, 2) || 0}</div>
                      </BootstrapTooltip>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {`${item?.overYearsIncomeMetric}` !== '0'
                        ? `${getNewNumberPercentage(
                            (item.preYearArrears + item.historicalArrears) / item?.overYearsIncomeMetric,
                            2,
                          )}`
                        : `0%`}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {getAdminNewNumber(item?.currentYearIncomeMetric + item?.overYearsIncomeMetric, 2) || 0}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item.projectId === '112' && typeObj[propertyCommunity?.typeValue] === '管理费'
                        ? 0
                        : getAdminNewNumber(
                            item?.currentYearArrears +
                              item?.currentMonthActual +
                              item?.currentYearPrepaidActual +
                              item?.preYearArrears +
                              item?.historicalArrears +
                              item?.preYearAllocCurrentMonth,
                            2,
                          ) || 0}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {`${item?.overYearsIncomeMetric + item?.currentYearIncomeMetric}` !== '0'
                        ? `${getNewNumberPercentage(
                            (item?.currentYearArrears +
                              item?.currentMonthActual +
                              item?.currentYearPrepaidActual +
                              item?.preYearArrears +
                              item.historicalArrears +
                              item.preYearAllocCurrentMonth) /
                              (item?.overYearsIncomeMetric + item?.currentYearIncomeMetric),
                            2,
                          )}`
                        : `0%`}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
        <div
          id="totalBox"
          // @ts-ignore
          style={{ paddingRight: paddingBoo ? 0 : 15 }}
          className={classes.totalBox}
        >
          <div style={{ width: '5.1%' }}>总计</div>
          <div>{getAdminNewNumber(totalObj?.currentYearAccountsReceivable, 2) || 0}</div>
          <div>{getAdminNewNumber(totalObj?.currentYearIncomeMetric, 2) || 0}</div>
          <div>{getAdminNewNumber(totalObj?.preYearAllocCurrentMonth, 2) || 0}</div>
          <div>{getAdminNewNumber(totalObj?.numberOfCompletions, 2) || 0}</div>
          {/*<div>*/}
          {/*  {getAdminNewNumber(*/}
          {/*    totalObj?.currentYearArrears + totalObj?.currentMonthActual + totalObj?.currentYearPrepaidActual,*/}
          {/*    2,*/}
          {/*  ) || 0}*/}
          {/*</div>*/}
          <div>
            {getAdminNewNumber(
              typeObj[propertyCommunity?.typeValue] === '管理费'
                ? totalObj?.currentYearArrears +
                    totalObj?.currentMonthActual +
                    totalObj?.currentYearPrepaidActual +
                    totalObj?.preYearAllocCurrentMonth -
                    buildingObj?.currentYearArrears -
                    buildingObj?.currentMonthActual -
                    buildingObj?.currentYearPrepaidActual -
                    buildingObj?.preYearAllocCurrentMonth
                : totalObj?.currentYearArrears +
                    totalObj?.currentMonthActual +
                    totalObj?.currentYearPrepaidActual +
                    totalObj?.preYearAllocCurrentMonth,
              2,
            ) || 0}
          </div>
          <div>
            {`${totalObj?.currentYearIncomeMetric}` !== '0'
              ? `${getNewNumberPercentage(
                  (typeObj[propertyCommunity?.typeValue] === '管理费'
                    ? totalObj?.currentYearArrears +
                      totalObj?.currentMonthActual +
                      totalObj?.currentYearPrepaidActual +
                      totalObj?.preYearAllocCurrentMonth -
                      buildingObj?.currentYearArrears -
                      buildingObj?.currentMonthActual -
                      buildingObj?.currentYearPrepaidActual -
                      buildingObj?.preYearAllocCurrentMonth
                    : totalObj?.currentYearArrears +
                      totalObj?.currentMonthActual +
                      totalObj?.currentYearPrepaidActual +
                      totalObj?.preYearAllocCurrentMonth) / totalObj?.currentYearIncomeMetric,
                  2,
                )}`
              : `0%`}
          </div>
          <div>{getAdminNewNumber(totalObj?.overYearsIncomeMetric, 2) || 0}</div>
          <div>{getAdminNewNumber(totalObj.preYearArrears + totalObj.historicalArrears, 2) || 0}</div>
          <div>
            {`${totalObj?.overYearsIncomeMetric}` !== '0'
              ? `${getNewNumberPercentage(
                  (totalObj.preYearArrears + totalObj.historicalArrears) / totalObj?.overYearsIncomeMetric,
                  2,
                )}`
              : `0%`}
          </div>
          <div>{getAdminNewNumber(totalObj?.currentYearIncomeMetric + totalObj?.overYearsIncomeMetric, 2) || 0}</div>
          <div>
            {getAdminNewNumber(
              typeObj[propertyCommunity?.typeValue] === '管理费'
                ? totalObj?.currentYearArrears +
                    totalObj?.currentMonthActual +
                    totalObj?.currentYearPrepaidActual +
                    totalObj?.preYearArrears +
                    totalObj.historicalArrears +
                    totalObj?.preYearAllocCurrentMonth -
                    buildingObj?.currentYearArrears -
                    buildingObj?.currentMonthActual -
                    buildingObj?.currentYearPrepaidActual -
                    buildingObj?.preYearAllocCurrentMonth
                : totalObj?.currentYearArrears +
                    totalObj?.currentMonthActual +
                    totalObj?.currentYearPrepaidActual +
                    totalObj?.preYearArrears +
                    totalObj.historicalArrears +
                    totalObj?.preYearAllocCurrentMonth,
              2,
            ) || 0}
          </div>
          <div>
            {`${totalObj?.currentYearIncomeMetric + totalObj?.overYearsIncomeMetric}` !== '0'
              ? `${getNewNumberPercentage(
                  (typeObj[propertyCommunity?.typeValue] === '管理费'
                    ? totalObj?.currentYearArrears +
                      totalObj?.currentMonthActual +
                      totalObj?.currentYearPrepaidActual +
                      totalObj?.preYearArrears +
                      totalObj.historicalArrears +
                      totalObj?.preYearAllocCurrentMonth -
                      buildingObj?.currentYearArrears -
                      buildingObj?.currentMonthActual -
                      buildingObj?.currentYearPrepaidActual -
                      buildingObj?.preYearAllocCurrentMonth
                    : totalObj?.currentYearArrears +
                      totalObj?.currentMonthActual +
                      totalObj?.currentYearPrepaidActual +
                      totalObj?.preYearArrears +
                      totalObj.historicalArrears +
                      totalObj?.preYearAllocCurrentMonth) /
                    (totalObj?.currentYearIncomeMetric + totalObj?.overYearsIncomeMetric),
                  2,
                )}`
              : `0%`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminPropertyDetails
